<template>

    <input v-model="value"

           :type="finalType"
           v-bind="$attrs"
           style="padding: 0;margin:0; appearance: none"

    />

    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"
          @click="passwordVisible = ! passwordVisible"
          style="pointer-events: auto; cursor: pointer"
    ></span>


</template>

<script>
    export default {
        props: {
            modelValue    : {},
            type: {

            },
            list: {} // prevent inheritence of list="" on element
        },
        data: function () {
            return {
                passwordVisible: false,
            };
        },
        emits: ['update:modelValue'],
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            },
            inputAttrs() {
                let attrs = {...this.$attrs};
                delete attrs.list;
                return attrs;
            },
            finalType () {
                if (this.passwordVisible) {
                    return 'text';
                } else {
                    return 'password';
                }
            }
        },

    }
</script>

<style scoped lang="scss">

</style>
